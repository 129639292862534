import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { Languages } from 'enums/languages.enum';
import en from './i18n/locales/en-US.json';
import fr from './i18n/locales/fr.json';
import es from './i18n/locales/es.json';
import pt from './i18n/locales/pt.json';
import jp from './i18n/locales/ja.json';
import sa from './i18n/locales/ar.json';
import id from './i18n/locales/id.json';
import vn from './i18n/locales/vi.json';
import tr from './i18n/locales/tr.json';
import { isProductionHost } from 'helpers/isProductionHost';

const resources = {
    en: {
        translation: en,
    },
    fr: {
        translation: fr,
    },
    es: {
        translation: es,
    },
    pt: {
        translation: pt,
    },
    tr: {
        translation: tr,
    },
    vn: {
        translation: vn,
    },
    id: {
        translation: id,
    },
    jp: {
        translation: jp,
    },
    sa: {
        translation: sa,
    },
};

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    // .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources,
        lng: Languages.en,
        fallbackLng: Languages.en,
        debug: !isProductionHost(),

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });

export default i18n;
