import PageFallback from 'components/PageFallback';
import { lazy, Suspense } from 'react';

let PrivacyPolicy = lazy(() => import('./PrivacyPolicy'));

export async function lazyPrivacyPolicyLoader() {
    const componentModule = await import('./PrivacyPolicy');
    // This avoid flicker from React.lazy by using the component directly
    PrivacyPolicy = componentModule.default as any;

    return null;
}

export function LazyPrivacyPolicy() {
    return (
        <Suspense fallback={<PageFallback />}>
            <PrivacyPolicy />
        </Suspense>
    );
}
