import PageFallback from 'components/PageFallback';
import { lazy, Suspense } from 'react';

let Share = lazy(() => import('./Share'));

export async function lazyShareLoader() {
    const componentModule = await import('./Share');
    // This avoid flicker from React.lazy by using the component directly
    Share = componentModule.default as any;

    return null;
}

export function LazyShare() {
    return (
        <Suspense fallback={<PageFallback />}>
            <Share />
        </Suspense>
    );
}
