import PageFallback from 'components/PageFallback';
import { lazy, Suspense } from 'react';

let SentSms = lazy(() => import('./SentSms'));

export async function lazySentSmsLoader() {
    const componentModule = await import('./SentSms');
    // This avoid flicker from React.lazy by using the component directly
    SentSms = componentModule.default as any;

    return null;
}

export function LazySentSms() {
    return (
        <Suspense fallback={<PageFallback />}>
            <SentSms />
        </Suspense>
    );
}
