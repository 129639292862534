import { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';

let CookiePolicy = lazy(() => import('./CookiePolicy'));

export async function lazyCookiePolicyLoader() {
    const componentModule = await import('./CookiePolicy');
    // This avoid flicker from React.lazy by using the component directly
    CookiePolicy = componentModule.default as any;

    return null;
}

export function LazyCookiePolicy() {
    const { t } = useTranslation();

    return (
        <Suspense fallback={t('loading')}>
            <CookiePolicy />
        </Suspense>
    );
}
