import CookiePolicy, { cookiePolicyLoader } from 'pages/CookiePolicy';
import ForgotPassword, { forgotPasswordLoader } from 'pages/ForgotPassword';
import LogIn, { logInLoader } from 'pages/LogIn';
import Main, { mainLoader } from 'pages/Main';
import NewPassword, { newPasswordLoader } from 'pages/NewPassword';
import Payment, { paymentLoader } from 'pages/Payment';
import PrivacyPolicy, { privacyPolicyLoader } from 'pages/PrivacyPolicy';
import Share, { shareLoader } from 'pages/Share';
import SuccessPayment, { successPaymentLoader } from 'pages/SuccessPayment';
import TermsOfUse, { termsOfUseLoader } from 'pages/TermsOfUse';
import SignUp, { signUpLoader } from 'pages/SignUp';
import DeclinePayment, { declinePaymentLoader } from 'pages/DeclinePayment';
import FakeSearch, { fakeSearchLoader } from 'pages/FakeSearch';
import Account, { accountLoader } from 'pages/Account';
import SentSms, { sentSmsLoader } from 'pages/SentSms';
import Location, { locationLoader } from 'pages/Location';
import RestrictedAccessByIp, { restrictedAccessByIpLoader } from 'pages/RestrictedAccessByIp';
import { AppRoutes } from 'enums/routes.enum';

const routes = [
    {
        path: AppRoutes.HOME,
        component: Main,
        loader: mainLoader,
    },
    {
        path: `${AppRoutes.SHARE}/:locationId`,
        component: Share,
        loader: shareLoader,
    },
    {
        path: AppRoutes.COOKIE_POLICY,
        component: CookiePolicy,
        loader: cookiePolicyLoader,
    },
    {
        path: AppRoutes.PRIVACY_POLICY,
        component: PrivacyPolicy,
        loader: privacyPolicyLoader,
    },
    {
        path: AppRoutes.TERMS_OF_USE,
        component: TermsOfUse,
        loader: termsOfUseLoader,
    },
    {
        path: AppRoutes.LOG_IN,
        component: LogIn,
        loader: logInLoader,
    },
    {
        path: AppRoutes.SIGN_UP,
        component: SignUp,
        loader: signUpLoader,
    },
    {
        path: AppRoutes.FORGOT_PASSWORD,
        component: ForgotPassword,
        loader: forgotPasswordLoader,
    },
    {
        path: AppRoutes.NEW_PASSWORD,
        component: NewPassword,
        loader: newPasswordLoader,
    },
    {
        path: AppRoutes.PAYMENT,
        component: Payment,
        loader: paymentLoader,
    },
    {
        path: AppRoutes.SUCCESS,
        component: SuccessPayment,
        loader: successPaymentLoader,
    },
    {
        path: AppRoutes.DECLINE,
        component: DeclinePayment,
        loader: declinePaymentLoader,
    },
    {
        path: AppRoutes.SEARCH,
        component: FakeSearch,
        loader: fakeSearchLoader,
    },
    {
        path: AppRoutes.ACCOUNT,
        component: Account,
        loader: accountLoader,
    },
    {
        path: AppRoutes.SENT_SMS,
        component: SentSms,
        loader: sentSmsLoader,
    },
    {
        path: `${AppRoutes.LOCATION}/:locationId`,
        component: Location,
        loader: locationLoader,
    },
    {
        path: '*',
        component: Main,
        loader: mainLoader,
    },
    {
        path: AppRoutes.RESTRICTED_ACCESS,
        component: RestrictedAccessByIp,
        loader: restrictedAccessByIpLoader,
    },
];

export default routes;
