import * as Sentry from '@sentry/react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { useEffect } from 'react';

Sentry.init({
    dsn: 'https://81c6f19f0f0e3040689dd9e958b86ad1@o4507176553283584.ingest.de.sentry.io/4507453264101456',
    environment: process.env.REACT_APP_NODE_ENV,
    release: process.env.REACT_APP_VERSION,

    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
        Sentry.replayIntegration({
            maskAllText: true,
            blockAllMedia: false,
        }),
        Sentry.httpClientIntegration({
            failedRequestStatusCodes: [400, 404, 500],
        }),
    ],
    tracesSampleRate: 0.1,
    profilesSampleRate: 0.1,
    replaysSessionSampleRate: 0.001,
    replaysOnErrorSampleRate: 0.001,
    beforeSend: (event) => {
        const isHttpError = event.exception?.values?.find((exceptions) => exceptions.mechanism?.type === 'http.client');
        const code = event.contexts?.response?.status_code;
        const url = event.request?.url;
        const method = event.request?.method;

        if (!!isHttpError && code && url && method) {
            const group = new URL(url).hostname;
            event.fingerprint = [group];
            isHttpError.value = `[${code}][${method}]: ${group}`;
            return event;
        }

        return event;
    },
});
