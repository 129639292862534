export enum Languages {
    en = 'en',
    fr = 'fr',
    es = 'es',
    pt = 'pt',
    jp = 'jp',
    sa = 'sa',
    id = 'id',
    vn = 'vn',
    tr = 'tr',
}

export enum Locales {
    en = 'en-US',
    fr = 'fr-FR',
    es = 'es-ES',
    pt = 'pt-PT',
    jp = 'ja-JP',
    sa = 'ar-SA',
    id = 'id-ID',
    vn = 'vi-VN',
    tr = 'tr-TR',
}

export const Countries: Record<string, string[]> = {
    fr: ['FR'],
    es: ['ES', 'AR', 'BO', 'CL', 'CO', 'EC', 'GY', 'PY', 'PE', 'SR', 'UY', 'VE', 'MX'],
    pt: ['PT', 'BR'],
    jp: ['JP'],
    sa: ['SA', 'AE', 'EG', 'IQ', 'JO', 'KW', 'LB', 'LY', 'MA', 'OM', 'QA', 'SY', 'TN', 'YE'],
    id: ['ID'],
    vn: ['VN'],
    tr: ['TR'],
};
