import PageFallback from 'components/PageFallback';
import { lazy, Suspense } from 'react';

let Account = lazy(() => import('./Account'));

export async function lazyAccountLoader() {
    const componentModule = await import('./Account');
    // This avoid flicker from React.lazy by using the component directly
    Account = componentModule.default as any;

    return null;
}

export function LazyAccount() {
    return (
        <Suspense fallback={<PageFallback />}>
            <Account />
        </Suspense>
    );
}
