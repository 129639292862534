import { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';

let Main = lazy(() => import('./Main'));

export async function lazyMainLoader() {
    const componentModule = await import('./Main');
    // This avoid flicker from React.lazy by using the component directly
    Main = componentModule.default as any;

    return null;
}

export function LazyMain() {
    const { t } = useTranslation();

    return (
        <Suspense fallback={t('loading')}>
            <Main />
        </Suspense>
    );
}
