import PageFallback from 'components/PageFallback';
import { lazy, Suspense } from 'react';

let SignUp = lazy(() => import('./SignUp'));

export async function lazySignUpLoader() {
    const componentModule = await import('./SignUp');
    // This avoid flicker from React.lazy by using the component directly
    SignUp = componentModule.default as any;

    return null;
}

export function LazySignUp() {
    return (
        <Suspense fallback={<PageFallback />}>
            <SignUp />
        </Suspense>
    );
}
