import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: 'Geologica, Arial, sans-serif',
    },
    palette: {
        mode: 'dark',
        background: {
            default: '#0C0F21',
        },
    },
});

export default theme;
