import PageFallback from 'components/PageFallback';
import { lazy, Suspense } from 'react';

let ForgotPassword = lazy(() => import('./ForgotPassword'));

export async function lazyForgotPasswordLoader() {
    const componentModule = await import('./ForgotPassword');
    // This avoid flicker from React.lazy by using the component directly
    ForgotPassword = componentModule.default as any;

    return null;
}

export function LazyForgotPassword() {
    return (
        <Suspense fallback={<PageFallback />}>
            <ForgotPassword />
        </Suspense>
    );
}
