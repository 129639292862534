import { PropsWithChildren, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { supabase } from 'services/supabase';
import { useUserStore } from 'providers/RootStoreProvider';

const AuthCheck = observer(({ children }: PropsWithChildren) => {
    const { setUser, fetchUserProfile } = useUserStore();
    const [isUserChecked, setIsUserChecked] = useState(false);

    useEffect(() => {
        const {
            data: { subscription },
        } = supabase.auth.onAuthStateChange(async (_event, session) => {
            const user = session?.user ?? null;

            setUser(user);
            if (user) {
                await fetchUserProfile(user?.email);
            }
            setIsUserChecked(true);
        });

        return () => subscription.unsubscribe();
    }, []);

    if (!isUserChecked) {
        return null;
    }

    return <>{children}</>;
});

export default AuthCheck;
