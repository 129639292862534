export enum AppRoutes {
    HOME = '/',
    SEARCH = '/search',
    SIGN_UP = '/sign-up',
    LOG_IN = '/login',
    PRIVACY_POLICY = '/privacy-policy',
    TERMS_OF_USE = '/terms-of-use',
    COOKIE_POLICY = '/cookie-policy',
    PAYMENT = '/payment',
    FORGOT_PASSWORD = '/forgot-password',
    ACCOUNT = '/account',
    SENT_SMS = '/sent-sms',
    LOCATION = '/location',
    NEW_PASSWORD = '/new-password',
    SUCCESS = '/success',
    DECLINE = '/decline',
    SECURE_FLOW_RESULT = '/secure-flow-result',
    SHARE = '/share',
    RESTRICTED_ACCESS = '/restricted',
}
