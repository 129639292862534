import PageFallback from 'components/PageFallback';
import { lazy, Suspense } from 'react';

let FakeSearch = lazy(() => import('./FakeSearch'));

export async function lazyFakeSearchLoader() {
    const componentModule = await import('./FakeSearch');
    // This avoid flicker from React.lazy by using the component directly
    FakeSearch = componentModule.default as any;

    return null;
}

export function LazyFakeSearch() {
    return (
        <Suspense fallback={<PageFallback />}>
            <FakeSearch />
        </Suspense>
    );
}
