import { createRoutesFromElements, Outlet, Route, RouterProvider } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import AmplitudeProvider from 'providers/AmplitudeProvider';
import { RootStoreProvider } from 'providers/RootStoreProvider';
import AuthCheck from 'components/AuthCheck';
import ScrollToTop from 'components/ScrollToTop';
import ScrollToHash from 'components/ScrollToHash';
import route from 'constants/routes';
import theme from './theme';
import { setLanguage } from 'helpers/language.helpers';
import { sentryCreateBrowserRouter } from 'services/sentry/sentry.router';

const RootLayout = () => {
    setLanguage();

    return (
        <RootStoreProvider>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <AuthCheck>
                    <AmplitudeProvider>
                        <ScrollToTop />
                        <ScrollToHash />
                        <Outlet />
                    </AmplitudeProvider>
                </AuthCheck>
            </ThemeProvider>
        </RootStoreProvider>
    );
};

const router = sentryCreateBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<RootLayout />}>
            {route.map((route: any) => (
                <Route path={route.path} key={route.path} element={<route.component />} loader={route.loader} />
            ))}
        </Route>
    )
);

function App() {
    return <RouterProvider router={router} />;
}

export default App;
