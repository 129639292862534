import { createRoot } from 'react-dom/client';
import App from './App';

import 'services/sentry/sentry.config';
import './index.css';
import './i18n.ts';

const rootElement = document.getElementById('root');

if (rootElement === null) {
    throw new Error("No element with id 'root' found");
}

const root = createRoot(rootElement);

root.render(<App />);
