import { isToday } from 'helpers/isToday';
import { getLocations } from 'http-client/location.client';
import { ILocation } from 'interfaces/location/location.interface';
import { action, makeObservable, observable } from 'mobx';
import { RootStore } from './root.store';

export class LocationsStore {
    rootStore: RootStore;
    locations: ILocation[] = [];
    location: ILocation | null = null;
    limit = 24;
    overLimit = false;
    loading = false;

    constructor(rootStore: RootStore) {
        makeObservable(this, {
            locations: observable,
            location: observable,
            loading: observable,
            overLimit: observable,
            setLocations: action,
            setLocation: action,
            setLoading: action,
            checkLimit: action,
        });
        this.rootStore = rootStore;
    }

    setLocation = (location: ILocation | null) => {
        this.location = location;
    };

    setLocations = (locations: ILocation[]) => {
        this.locations = locations;
    };

    setLoading = (loading: boolean) => {
        this.loading = loading;
    };

    fetchLocations = async () => {
        this.setLoading(true);
        const locations = await getLocations();
        this.setLocations(locations || []);
        this.setLoading(false);
        this.checkLimit();
    };

    checkLimit = () => {
        const createdToday = this.locations.filter((item) => {
            return isToday(item.createdAt);
        });

        if (createdToday.length >= this.limit) {
            this.overLimit = true;
        } else {
            this.overLimit = false;
        }
    };

    hydrate = (data: any) => {
        if (!data) {
            return;
        }
        if (data.locations) {
            this.locations = data.locations;
        }
    };
}
