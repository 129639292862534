import axios from 'axios';
import { ATTRIBUTION_URL } from 'constants/env';
import { EndpointsAttribution } from 'enums/endpoint.enum';

export interface ICreateUserAttributionParams {
    project_name: string;
    device_id: string;
    user_id: string;
    product_id?: string;
    device_category?: string;
    browser?: string;
    browser_version?: string;
    operating_system?: string;
    operating_system_version?: string;
    device_model?: string;
    device_brand?: string;
    device_language?: string;
    country?: string;
    utm_source?: string;
    utm_medium?: string;
    utm_campaign?: string;
    utm_content?: string;
    utm_term?: string;
    utm_ad?: string;
    utm_placement?: string;
    gclid?: string;
    fbclid?: string;
}

export const createUserAttribution = async (params: ICreateUserAttributionParams) => {
    try {
        const response = await axios.post(`${ATTRIBUTION_URL}${EndpointsAttribution.USER_ATTRIBUTION_CREATE}`, params);

        if (response.status === 200 || response.status === 201) {
            return response?.data;
        }

        return null;
    } catch (e) {
        console.error('Cannot create user properties on attribution server');
        return null;
    }
};
